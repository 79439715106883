<template>
  <div>
    <el-dialog title="添加平台助手" width="600px" v-model="dialogVisible" @close='closeDialogAction'>
      <div class="account-box">
        <img :src="qrcode" alt="">
      </div>
      <template #footer>
        <div class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取消</el-button> -->
          <el-button type="primary" @click="confirmClick">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref} from "vue"
import {InHouseQrcode} from '@/helper/robot'
export default {
  name: "CreateCodeRob",
  setup(props, context) {
    const dialogVisible = ref(false);
    const serialNo = ref('');
    const qrcode = ref('');
    const timer = ref(null);
    //父组件调用这里的方法
    const showDialog = (SerialNo) => {
      serialNo.value=  SerialNo||''
      dialogVisible.value = true;
      startTimer()
    };

    //开启计时器
    function startTimer() {
      if(timer.value){
        return;
      }
      timer.value = setInterval(() => {
        requestQrcode()
      }, 1000);
    }

    //关闭计时器
    function closeTimer() {
      if(timer.value){
        clearInterval(timer.value)
        timer.value = null
      }
    }

    //获取二维码
    function requestQrcode() {
      let param = {
        SerialNo:serialNo.value,
        RobotSerialNo:""
      }
      InHouseQrcode(param).then(res=>{
        if(res.QRCode&&res.QRCode.length>0){
          qrcode.value = res.QRCode
          closeTimer()
        } 
      })
    }

    /***************点击事件*****************/
    function closeDialogAction() {
      closeTimer()
    }
    function confirmClick(){
      dialogVisible.value = false;
      closeTimer()
      context.emit("onConfirm");
    };
    return {
      dialogVisible,
      confirmClick,
      showDialog,
      serialNo,
      timer,
      qrcode,
      closeTimer,
      startTimer,
      closeDialogAction
    };
  },
};
</script>
<style lang='scss' scoped>
.header {
  display: flex;
  align-items: center;
}
.link {
  margin-top: 30px;
}
.account-box {
  text-align: center;
  .account-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  img{
    width: 300px;
    height: 300px;
  }
}
</style>