<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>助手名称或ID:</span>
            <el-input
              v-model="searchOption.key"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
            <span>状态:</span>
            <el-select v-model="searchOption.status" placeholder="全部">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div>
          管理助手(共<span class="numCol">{{ pageObj.TotalRow || 0 }}</span
          >个)
        </div>
        <div style="display:flex;align-items: center;">
          <el-button type="primary" @click="goAbandoned">废弃账号列表</el-button>
          <el-button type="primary" @click="confirmRobot">添加托管助手</el-button>
          <el-button type="primary" @click="addRobot">添加平台助手</el-button>
          <el-button type="primary" @click="downloadList">导出名单</el-button>
          
          <el-upload
            class="avatar-uploader"
            style="margin-left:5px"
            accept=".xlsx"
            action=""
            :http-request="changeList"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :auto-false="false"
          >
            <el-button type="primary">导入名单</el-button>
          </el-upload>
        </div>
      </div>

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="IndexID" width="50" label="序号" align="center"></el-table-column>
        <el-table-column prop="NickName" label="助手名称"></el-table-column>
        <el-table-column prop="WxAlias" label="助手ID" align="center" width="80"></el-table-column>
        <el-table-column prop="Email" label="邮箱"></el-table-column>
        <el-table-column prop="ShowRobotType" label="类型" align="center" width="70"></el-table-column>
        <el-table-column prop="qrcode" label="二维码" align="center">
          <template #default="scope">
            <el-popover placement="left" trigger="hover">
              <img
                :src="scope.row.QrCode"
                alt=""
                style="width: 150px; height: 150px"
              />
              <template #reference>
                <img
                  :src="scope.row.QrCode"
                  style="height: 50px; width: 50px"
                />
              </template>
              <!--trigger属性值：hover、click、focus 和 manual-->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="GroupCount"
          label="已开通群数"
          align="center"
          width="90"
        >
         <template #default="scope">
            <div>
              <div @click="goGroupDetail(scope.row)" class="hasUse">{{scope.row.GroupCount || 0}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="助手状态" align="center">
          <template #default="scope">
            <div>
              <div v-if="scope.row.IsOnline == 10">
                {{ scope.row.status }}
              </div>
              <div v-else>
                {{ scope.row.status }}(<span
                  class="color"
                  @click="againLogin(scope.row)"
                  >重新登录</span
                >)
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="是否自动入群" align="center" width="120">
          <template #default="scope">
            <div class="group-list">
              <div class="group-item">
                <div v-if="scope.row.IsAutoJoinChatRoom">是</div>
                <div v-else>否</div>
              </div>
              <el-switch
                v-model="scope.row.IsAutoJoinChatRoom"
                active-color="#13ce66"
                inactive-color="#ccc"
                @click="changeAutoJoinChatRoom(scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="addFriend"
          label="是否自动通过好友"
          align="center"
          width="160"
        >
          <template #default="scope">
            <div class="group-list">
              <div class="group-item">
                <div v-if="scope.row.IsAutoAddFriend">是</div>
                <div v-else>否</div>
              </div>
              <el-switch
                v-model="scope.row.IsAutoAddFriend"
                active-color="#13ce66"
                inactive-color="#ccc"
                @click="changeAutoAddFriend(scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button plain class="color" v-if="scope.row.IsOnline==10" @click="offline(scope.row)">强制离线</el-button>
            <el-button plain class="color" @click="syncGroup(scope.row)" v-if="scope.row.IsOnline!=11">同步群信息</el-button>
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
    </div>
    <CreateRobAddress ref="creRobAddress" @onConfirm="confirmAddress" />
    <CreateCodeRob ref="createCodeRob" @onConfirm="confirmCodeRob"></CreateCodeRob>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import CreateRobAddress from "@/components/RobotMag/CreateRobAddress";
import CreateCodeRob from "@/components/RobotMag/CreateCodeRob";
import { ElMessageBox, ElMessage } from "element-plus";
import { GetRobotList,SetOffline,GetAllGroup,InHouseLogin,UpdateIsAutoJoinGroup,UpdateIsAutoAddFriend} from "@/helper/robot";
import {RobotBatchUpdate} from "@/helper/company";
export default {
  name: "GroupSend",
  components: {
    TableFoot,
    CreateRobAddress,
    CreateCodeRob,
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const creRobAddress = ref(null);
    const createCodeRob = ref(null);

    onMounted(() => {
      searchAction();
    });

    function createAction() {
      router.push("./CreateGroupSend");
    }
    function goGroupDetail(item) {
      router.push({ name: "GroupDetail", params: { SID: item.SerialNo} });
    }
    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: 0 },
      { label: "正常", value: 10 },
      { label: "离线", value: 11 },
    ];
    const searchOption = reactive({
      key: "",
      status:0,
    });
    function addRobot() {
      router.push("/robotpage/createrobot");
    }
    //执行搜索
    function searchAction() {
      let param = {
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize,
	      Name:searchOption.key,
        Type:searchOption.status
      };
      GetRobotList(param).then(res=>{
          pageObj.TotalRow = res.TotalCount;
          tableData.value = res.List
          res.List.forEach(item=>{
            item.status = item.IsOnline==10?"正常":"离线"
            item.IsAutoJoinChatRoom = item.IsAutoJoinChatRoom==1?true:false
            item.IsAutoAddFriend = item.IsAutoAddFriend==1?true:false
            
          })
      })
    }
    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchOption.status = 0;
      searchAction();
    }

    /*****************导入******************/
    //检查文件
    function beforeUpload(file){
        // 校验文件
        const isType = file.name.indexOf(".xlsx")>-1;
        if (!isType) {
            ElMessage({
                type: "error",
                message: "上传图片只支持xlsx!",
            });
        }
        return isType
    }
    /***************点击事件****************/
    //去废弃列表
    function goAbandoned(){
      router.push("/abandoned");
    }
    //导出
    function downloadList(){
        let param={
          PageNum:pageObj.PageIndex,
          PageSize:99999,
          Name:searchOption.key,
          Type:searchOption.status,
          Output:1
        }
        GetRobotList(param).then(res=>{
          window.location.href = res.File;
          ElMessage({
              type: 'success',
              message: '导出成功!',
          });
        })
    }
    //导入
    function changeList(event){
      const formData = new FormData();
      formData.append("file", event.file);
      RobotBatchUpdate(formData).then(res=>{
        searchAction()
      })
    }

    //添加平台助手
    function confirmRobot() {
      creRobAddress.value.showDialog();
    }
    //地理弹框确认
    function confirmAddress(SerialNo) {
      createCodeRob.value.showDialog(SerialNo);
    }

    //二维码弹框确认
    function confirmCodeRob(params) {
      searchAction()
    }
    //强制离线
    function offline(row) {
      ElMessageBox.confirm(
        `确认将助手【${row.NickName}】设置为离线？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          SetOffline({SerialNo:row.SerialNo}).then(res=>{
              ElMessage({
                message:"已成功离线",
                type: "success",
              })
              searchAction()
          })
        }).catch(() => {});
    }

    //同步群消息
    function syncGroup(row) {
      GetAllGroup({SerialNo:row.SerialNo}).then(res=>{
        ElMessage({
          showClose: true,
          message: "已成功同步群消息",
          type: "success",
        });
      })
    }
    //重新登录
    function againLogin(row) {
      ElMessageBox.confirm(
        `请确认登录的助手名称：【${row.NickName}】`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          creRobAddress.value.showDialog(row.SerialNo);
        }).catch(() => {});
    }

    //自动加入群
    function changeAutoJoinChatRoom(row) {
      let param = {
          SerialNo:row.SerialNo,
          Switch:row.IsAutoJoinChatRoom?1:0
      }
      UpdateIsAutoJoinGroup(param).then(res=>{
        // row.IsAutoJoinChatRoom = !row.IsAutoJoinChatRoom;
      })
    }

    //自动添加好友
    function  changeAutoAddFriend(row) {
      let param = {
          SerialNo:row.SerialNo,
          Switch:row.IsAutoAddFriend?1:0
      }
      UpdateIsAutoAddFriend(param).then(res=>{
        // row.IsAutoAddFriend = !row.IsAutoAddFriend;
      })
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    return {
      tableData,
      searchOption,
      statusList,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      createAction,
      offline,
      syncGroup,
      addRobot,
      confirmRobot,
      creRobAddress,
      confirmAddress,
      createCodeRob,
      againLogin,
      changeAutoJoinChatRoom,
      changeAutoAddFriend,
      confirmCodeRob,
      goGroupDetail,
      downloadList,
      beforeUpload,
      changeList,
      goAbandoned
    };
  },
};
</script>

<style lang="scss" scoped>
.numCol {
  color: #bfb18a;
}
.fail-wrap {
  display: flex;
  flex-direction: row;
  span {
    color: $color-common;
  }
}
.color {
  color: $color-common;
  cursor: pointer;
}
.group-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .group-item {
    margin: 0 10px;
  }
}
.hasUse{
    text-decoration: underline;
    cursor: pointer;
    color: $color-common;
}
</style>
