<template>
  <div>
    <el-dialog title="添加平台助手" width="600px" v-model="dialogVisible">
      <div class="account-box">
        <div>选择登录地域</div>
        <div class="account-item">
          <div>省份:</div>
          <el-select v-model="resultData.province" placeholder="请选择" @change="changeProvince" value-key="ID"> 
            <el-option
              v-for="item in provinceList"
              :key="item.ID"
              :label="item.Province"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="account-item">
          <div>城市:</div>
          <el-select v-model="resultData.city" placeholder="请选择" @change="changeCity" value-key="ID">
            <el-option
              v-for="item in cityList"
              :key="item.ID"
              :label="item.City"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmClick">登录</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { GetProvinceList,GetCityList,InHouseLogin} from "@/helper/robot.js";
import { ElMessageBox,ElMessage } from 'element-plus';
export default {
  name: "CreateRobAddress",
  setup(props, context) {
    const dialogVisible = ref(false);
    const resultData = reactive({
      province:null,
      city:null,
    })
    const provinceList = ref([]);
    const cityList = ref([]);
    const exitRobotNo=ref('')

    //父组件调用这里的方法
    const showDialog = (row) => {
      exitRobotNo.value = row||''
      dialogVisible.value = true;
      getProvinceList()
    };


    function getProvinceList() {
        GetProvinceList().then(res=>{
          provinceList.value = res.ProvinceList;
          for(let item of provinceList.value){
            if(item.Province == '上海市'){
              resultData.province = item
              getCityList(true)
            }
          }
        })
    }

    function getCityList(isDefault=false){
        GetCityList({pid:resultData.province.ID}).then(res=>{
          cityList.value = res.CityList;
          resultData.city = isDefault?res.CityList[0]:null;
        })
    }

    //更改省份
    function changeProvince(obj) {
      resultData.province = obj;
      console.log(obj)
      getCityList();
    }

    //更改城市
    function changeCity(obj) {
      resultData.city = obj;
    }
    
    /***************点击事件******************/
    const confirmClick = () => {
      if(!resultData.province){
        ElMessage({
            type: 'error',
            message: '请选择省份',
        })
        return
      }

      if(!resultData.city){
        ElMessage({
            type: 'error',
            message: '请选择城市',
        })
        return
      }

      let param ={
        RobotSerialNo:exitRobotNo.value,
        City:resultData.city.City,
        Code:resultData.city.Code
      }
      InHouseLogin(param).then(res=>{
        dialogVisible.value = false;
        context.emit("onConfirm",res.SerialNo);
          
      })
    };
    return {
      dialogVisible,
      confirmClick,
      showDialog,
      resultData,
      provinceList,
      cityList,
      getCityList,
      changeProvince,
      changeCity,
      exitRobotNo
    };
  },
};
</script>
<style lang='scss' scoped>
.header {
  display: flex;
  align-items: center;
}
.link {
  margin-top: 30px;
}
.account-box {
  text-align: center;
  .account-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  div {
    margin-right: 10px;
  }
}
</style>